import React from 'react';
import { Button } from 'primereact/button';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa'; // Para ícones do WhatsApp e Instagram
import logo from './assets/images/logo.png'; // Imagem do logo
import './assets/styles/FaleConosco.css';

const FaleConosco = () => {
    return (
        <div className="fale-conosco-container">
            <div className="fale-conosco-content">
                <div className="coluna esquerda">
                    <img src={logo} alt="Logo da Empresa" className="logo-image" />
                </div>
                <div className="coluna direita">
                    <h3>Fale Conosco</h3>
                    <p>Telefone: (11) 94229-4650</p>
                    <p>Email: rreformassabiny@gmail.com</p>
                    <div className="social-buttons">
                        <Button
                            label="Fale Conosco pelo WhatsApp"
                            icon={<FaWhatsapp />}
                            className="p-button-success"
                            onClick={() => window.open('https://wa.me/5511942294650', '_blank')}
                        />
                        <Button
                            label="Siga-nos no Instagram"
                            icon={<FaInstagram />}
                            className="p-button-info"
                            onClick={() => window.open('https://www.instagram.com/reformassabiny', '_blank')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaleConosco;

import React from 'react';
import './assets/styles/SobreNos.css'; // Seu arquivo de estilos

const SobreNos = () => {
    return (
        <section className="sobre-nos">
            <div className="sobre-nos-container">
                <div className="coluna esquerda">
                    <img src={require('./assets/images/banner-sobre-nos.png')} alt="Banner Sobre Nós" className="banner-image-sobre-nos" />
                </div>
                <div className="coluna direita">
                    <h2>Sobre a Nossa Empresa</h2>
                    <p>A Sabiny Marido de Aluguel é uma empresa dedicada a oferecer soluções completas para as necessidades do seu lar ou empreendimento. Além dos serviços tradicionais de marido de aluguel, nossa expertise se estende a diversas áreas, como hidráulica predial e residencial, elétrica, alvenaria, porcelanato, azulejos diversos, mármores e granitos, gesso drywall e pintura profissional para ambientes residenciais e prediais.</p>
                    <p>Nosso compromisso é entregar excelência e inovação em cada trabalho que realizamos. Com profissionais altamente qualificados, garantimos que sua demanda será atendida com a máxima eficiência e responsabilidade. Seja para solucionar pequenos problemas ou realizar grandes reformas, estamos aqui para otimizar seu tempo e trazer tranquilidade ao seu dia a dia.</p>
                    <p>Conte conosco para transformar suas preocupações em soluções. Não hesite em entrar em contato e descobrir como podemos ajudar!</p>
                </div>
            </div>
        </section>
    );
};

export default SobreNos;

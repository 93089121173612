import React from 'react';
import { Card } from 'primereact/card';
import './assets/styles/NossosServicos.css';

const servicos = [
    { nome: 'Drywall', imagem: 'drywall.jpg' },
    { nome: 'Telhado', imagem: 'telhado.png' },
    { nome: 'Porcelanato', imagem: 'porcelanato.jpg' },
    { nome: 'Azulejos em Geral', imagem: 'azulejos.jpg' },
    { nome: 'Mármores e Granitos', imagem: 'marmores.png' },
    { nome: 'Pintura', imagem: 'pintura.png' },
    { nome: 'Marido de Aluguel', imagem: 'marido.jpeg' },
    { nome: 'Elétrica', imagem: 'eletrica.png' },
    { nome: 'Alvenaria', imagem: 'alvenaria.jpg' },
    { nome: 'Gesso', imagem: 'gesso.jpeg' },

];

const NossosServicos = () => {
    return (
        <section className="nossos-servicos">
            <div className="servicos-container">
                {servicos.map((servico, index) => (
                    <div key={index} className="servico-item">
                        <Card
                            className="servico-card"
                            style={{ backgroundImage: `url(${require(`./assets/images/${servico.imagem}`)})` }}
                        >
                            <div className="servico-overlay">
                                <h3>{servico.nome}</h3>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default NossosServicos;

import React, { useState } from 'react';
import './assets/styles/FormOrcamento.css';
import { Button } from 'primereact/button';
import axios from 'axios';

const FormOrcamento = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Usar a variável de ambiente para o endpoint da API
            const response = await axios.post(`${process.env.REACT_APP_API_URL}`, {
                nome,
                email,
                mensagem
            });

            // Supondo que a resposta do servidor contenha uma mensagem no campo 'message'
            setStatus(`${response.data.message}`);
        } catch (error) {
            console.error('Erro ao enviar e-mail:', error);
            setStatus('Erro ao enviar e-mail.');
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="form-orcamento">
                <label>Nome:</label>
                <input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                />
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label>Mensagem:</label>
                <textarea
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                />
                <Button type="submit" label="Enviar orçamento" icon="pi pi-pencil" />
                {status && <p>{status}</p>}
            </form>
        </div>
    );
};

export default FormOrcamento;

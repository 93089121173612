// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DescricaoTrabalhos from './components/DescricaoTrabalhos';
import FormOrcamento from './components/FormOrcamento';
import FormasDePagamento from './components/FormasDePagamento';
import FaleConosco from './components/FaleConosco';
import NossosServicos from './components/NossosServicos';
import SobreNos from './components/SobreNos';
import 'primereact/resources/themes/lara-dark-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './components/assets/styles/App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <img src={require('./components/assets/images/banner.png')} alt="Banner" className="banner-image" />
        <main>
          <Routes>
            <Route path="/orcamento" element={<FormOrcamento />} />
          </Routes>
          <DescricaoTrabalhos />
          <NossosServicos />
          <SobreNos />
        </main>
        <footer>
          <FormasDePagamento />
          <FaleConosco />
        </footer>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './assets/styles/DescricaoTrabalhos.css';

const DescricaoTrabalhos = () => {
    return (
        <section className="descricao-trabalhos">
            <div className="custom-container">
                <h2>Bem-vindo à Sabiny Marido de Aluguel!</h2>
                <p>Na Sabiny, oferecemos soluções completas para todas as suas necessidades de reparo e manutenção, seja em casa, apartamento, condomínio ou empresa. Nosso time de profissionais altamente qualificados está pronto para garantir serviços de alta qualidade com eficiência, segurança e rapidez.</p>
                <p>Deixe suas preocupações conosco e aproveite a tranquilidade de ter um profissional especializado para cada necessidade. Na Sabiny, sua satisfação é a nossa prioridade!</p>
                <Link to="/orcamento">
                    <Button label="Faça seu orçamento" icon="pi pi-pencil" className='btn-orcamento' />
                </Link>
            </div>
        </section>
    )
}


export default DescricaoTrabalhos;

import React from 'react';
import { Card } from 'primereact/card'; // Usando o Card do PrimeReact para estilizar
import metodoPagamentIcon from './assets/images/metodoPagamento.png'; // Imagem com todos os métodos de pagamento
import './assets/styles/FormasDePagamento.css';  // Seus estilos personalizados

const FormasDePagamento = () => {
    return (
        <Card title="Formas de Pagamento" className="formas-pagamento-card">
            <div className="formas-pagamento-content">
                <div className="payment-method">
                    <img src={metodoPagamentIcon} alt="Métodos de Pagamento" />
                </div>
            </div>
        </Card>
    );
};

export default FormasDePagamento;
